@import './styles/globals.scss';

*, *:before, *:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: $htmlFontSize;
}

body {
  color: $textDark;
  font-family: $fontFamilyBody;
  font-size: rem(13);
}

#app-root {
  min-height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $fontFamilyHeading;
  margin: 0 0 rem(10);
}

p {
  margin: 0 0 rem(13);
}

ul {
  list-style-type: disc;
  margin-top: 0;
  padding-left: rem(16);
}

button {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
}

table {
  width: 100%;
}

th {
  text-align: left;
}

input {
  font-family: $fontFamilyBody;
}
.contentNoSideNav {
  position: relative;
  padding-top: $headerHeight + $navbarHeight + 15px;
  padding-left: 43px;
}