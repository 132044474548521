@import '../../styles/globals.scss';

.formViewWrapper {
  width: 100%;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  button {
    font-size: $fontSizeMedium;
  }
}

.title {
  font-size: rem(28);
  font-weight: $fontWeightSemiBold;
}
.content {
  padding: 0 rem(65) rem(65) 0;
}

.editing {
  max-width: rem(800);
}

.preview {
  width: 100%;
}

.buttonRow {
  display: flex;
  align-items: center;
  margin-top: rem(50);
}