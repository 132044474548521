.topGrid{
  margin-bottom: 25px;
}

.datepicker{
  margin-bottom: 0;
}

.bigSpacer{
  display: inline-block;
  margin-top: 25px;
  margin-bottom: 25px;
}

.smallSpacer{
  margin-bottom: 20px;
}