@import '../../styles/globals.scss';

.filter {
  padding: 0 rem(65);
  width: rem(400);
}
.filterSelect {
  color: $white;
  
  :global .reactSelect__control {
    background: $darkestBlue;
    border-color: $blueGray;
  }
  :global .reactSelect__value-container {
    padding: 0 rem(12);
  }
  :global .reactSelect__single-value {
    color: $white;
  }
  :global .reactSelect__dropdown-indicator {
    svg {
      display: none;
    }
  }
  :global .reactSelect__indicator-separator {
    display: none;
  }
  :global .reactSelect--is-disabled,
  :global .reactSelect__control--is-disabled {
    pointer-events: auto;
    cursor: not-allowed;
    opacity: 0.7;
  }

  :global .reactSelect__menu {
    background: $darkestBlue;
    z-index: 1;
  }
  :global .reactSelect__group {
    padding: 0;
    position: relative;
    
    &:before {
      border-left: 1px solid $mediumGray;
      content: '';
      display: block;
      width: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: rem(20);
    }

    .reactSelect__option {
      padding-left: rem(30);
    }
  }
  :global .reactSelect__group-heading {
    display: none;
  }

  :global .reactSelect__option {
    &--is-selected {
      background-color: $mediumGray;
    }
    &--is-focused {
      background-color: $brightBlue;
    }
  }
}

.uploadResourceModal {
  width: rem(420);
}

.formField {
  margin-bottom: rem(30);
}

.fileType {
  text-transform: uppercase;
}