@import '../../styles/globals.scss';

.goBackButton {
  color: $white;
  font-size: $fontSizeSmall;
  font-weight: $fontWeightNormal;
  text-transform: uppercase;

  &:hover,
  &:active,
  &:focus {
    color: $white;
  }
}

.title{
  font-size: rem(32);
  font-weight: $fontWeightSemiBold;
  margin: 0;
}

.reviewInfoText {
  font-size: $fontSizeMedium;
}

.coverEdit {
  width: rem(300);
  
  label {
    margin-top: rem(30);
  }
}

.coverDate {
  font-family: $fontFamilyHeading;
  font-size: $fontSizeMedium;
}

.fieldRow {
  display: flex;
}

.tallTextArea, .rollCallList {
  font-size: $fontSizeMedium;
  min-height: rem(420);
  width: rem(254);
}

.tallTextArea + .tallTextArea,
.rollCallList + .rollCallList {
  margin-left: rem(30);
}

.blankListItem {
  list-style: none;
  height: rem(20);
}

.barsList {
  ul {
    font-size: $fontSizeMedium;
    list-style: none;
    margin: 0 0 rem(40);
    padding: 0;
  }

  li {
    margin: 0 0 rem(10);
  }
}

.phaseLegend {
  margin: 0;
}

.phaseLegendHeading {
  margin: 0;
}

.phaseList {
  list-style: none;
  margin: 0;
  padding: 0;

  .short {
    display: inline-block;
    margin-right: rem(10);
    min-width: rem(20);
  }
}