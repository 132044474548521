@import '../../styles/globals.scss';

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 32%;

  &:nth-child(3n+1),
  &:nth-child(3n+2) {
    margin-right: 1%;
  }
}

.cardContent {
  border: 1px solid gray;
  border-radius: $borderRadius;
  padding: rem(24) rem(12) rem(36);
  position: relative;
  width: 100%;
}