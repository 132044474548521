
@import '../../styles/globals.scss';

.checkboxLabel {
  cursor: pointer;
  display: flex;
  margin: rem(10) 0 0 rem(20);
  position: relative;
}

.checkboxLabelText {
  display: block;
  font-size: rem(16);
  font-weight: $fontWeightMedium;
  margin-right: rem(5);
}

.checkboxIcon {
  color: $white;
}

.checkboxInput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}