@import '../../../styles/globals.scss';

.spacer{
    margin-top: rem(10);
    margin-bottom: rem(20);
}

.header{
    margin-bottom: rem(10);
    margin-top: rem(10);
}