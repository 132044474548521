@import '../../styles/globals.scss';

$contentWidth: rem(1237);

.dashboard {
  background: repeating-linear-gradient(
    -45deg,
    $darkestBlue,
    $darkestBlue rem(50),
    #101338 rem(50),
    #101338 rem(100)
  );
  color: $white;
  min-height: 100vh;
  padding-top: rem(105);
  padding-left: rem(65);
  padding-right: rem(65);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: $contentWidth;
}

.title {
  font-size: rem(44);
}

.newActionButton {
  width: rem(168);
}
.content {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: $contentWidth;
  padding-bottom: rem(100);
}

.contentLeft {
  max-width: rem(435);
  width: 100%;
}
.contentRight {
  display: flex;
  flex-direction: column;
  margin-left: rem(67);
  max-width: rem(735);
  width: 100%;
}
.contentRightTop {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: rem(40);
}

.panel + .panel {
  margin-left: rem(24);
}

.panelHeading {
  display: flex;
  align-items: flex-end;
  font-size: rem(13);
  font-weight: $fontWeightMedium;
  height: rem(40);
  white-space: pre-wrap;
}

// extend this class for common white background elements
.tile {
  background: $white;
  color: $textDark;
  padding: rem(16) rem(20) rem(14);
}

.chipColumns {
  display: flex;
}
.chipRows {
  display: grid;
  grid-auto-rows: rem(95);
}



.chip {
  @extend .tile;
  cursor: pointer;
  width: rem(168);

  &.active {
    .chipValue {
      color: $brightBlue;
    }
  }
}

.chipRows + .chipRows,
.chipColumns > .chip + .chip {
  border-left: 1px solid $borderGray;
}

.chipRows > .chip + .chip {
  border-top: 1px solid $borderGray;
}
.chipHeading {
  font-size: rem(11);
  font-weight: $fontWeightMedium;
  margin: 0;
}

.chipValue {
  font-size: rem(48);
  font-weight: $fontWeightExtraLight;
  line-height: 1.2;
  margin: 0;
}

.allDraftsModal {
  width: rem(700);
}

.draftItem {
  display: flex;
  justify-content: space-between;
}

.draftItemLink {
  font-size: rem(16);
}

.reviewRow {
  display: flex;
  align-items: flex-start;
  padding: rem(4) 0 rem(12);
}
.reviewRow + .reviewRow {
  border-top: 1px solid $borderGray;
  padding-top: rem(12);
}
.reviewToggle {
  background: transparent;
  border: none;
  color: $mediumGray;
  cursor: pointer;
  padding: rem(6);

  &:disabled {
    cursor: default;
    opacity: 0;
  }
}

.reviewToggleIcon {
  height: rem(13);
}

.reviewDate {
  background: $brightBlue;
  color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $fontFamilyHeading;
  line-height: 1;
  padding: rem(4);
}
.reviewDateDay {
  font-size: rem(22);
}
.reviewDateMonth {
  font-size: rem(11);
  font-weight: $fontWeightMedium;
  text-transform: uppercase;
}
.reviewInfo {
  flex-grow: 1;
  font-family: $fontFamilyHeading;
  padding: 0 rem(18);
}
.reviewStep {
  font-weight: $fontWeightMedium;
  margin: 0;
}
.reviewBar + .reviewBar{
  margin-top: rem(10);
}
.noResults {
  font-size: rem(28);
  font-weight: $fontWeightExtraLight;
  text-align: center;
}

.activityRow {
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
  padding: rem(6) 0;
}
.activityRow + .activityRow {
  border-top: 1px solid $borderGray;
}

.activityLink {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex-grow: 1;
  margin-top: rem(3);

  &:hover {
    white-space: normal;
  }
}
.activityDate {
  margin: 0 rem(16);
  white-space: nowrap;
}
