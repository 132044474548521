@import '../../styles/globals.scss';

.container {
  display: flex;
  align-items: flex-start;
}

.sideNav {
  background: transparent linear-gradient(#F8F8F8 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: rem(70) rem(65) 0;
  min-width: rem(302);
  flex-basis: calc(100%/(1368/302));
}

.modal {
  width: rem(500);
}

.mainContent {
  width: 100%;
}