@import '../../styles/globals.scss';

.contentView {
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  flex-grow: 1;
}

.sideNav {
  background: transparent linear-gradient(#F8F8F8 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  padding: rem(70) rem(30) 0 rem(65);
  min-width: rem(302);
  flex-basis: calc(100%/(1368/302));
}

.mainContent {
  max-width: rem(1200);
  padding: rem(50) rem(70);
  width: 100%;
}