@import '../../styles/globals.scss';

.subTitle {
  font-weight: $fontWeightMedium;
  font-size: rem(20);
}

.tinyHeading {
  font-size: rem(12);
  margin: 0;
  opacity: 0.4;
}

.modalMedium {
  max-width: rem(550);
  width: 100%;
}

.modalButtons {
  display: flex;
  justify-content: space-between;
}

.modalSubTitle {
  font-size: rem(16);
  font-weight: $fontWeightMedium;
}

.resourceSection {
  margin: rem(40) 0 0;
}

.staticResourceReplace {
  margin-top: rem(20);
}