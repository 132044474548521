@import '../../styles/globals.scss';

.collapsible {
  margin-bottom: rem(24);
}
.toggle {
  background-color: transparent;
  border: none;
  display: block;
  align-items: center;
  cursor: pointer;
  padding: rem(5) 0;
  position: relative;
  text-align: left;
  width: 100%;
  @include transition(background-color);

  &:hover,
  &:focus {
    background-color: $lightGray;
    outline: none;
  }
}

.toggleContent {
  flex-grow: 2;
}
.toggleIcon {
  background-color: inherit;
  padding: 0 rem(5);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  width: rem(30);

}
.icon {
  height: rem(15);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.closed .children {
  height: 0;
  overflow: hidden;
}