@import '../../styles/globals.scss';

.navbar {
  background-color: $blueGray;
  color: $white;
  display: flex;
  justify-content: space-between;
  height: $navbarHeight;
  padding: 0 $contentSideMarginWidth;
  font-family: $fontFamilyHeading;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;   
  z-index: $navbarZ;
}

.leftSection {
  display: flex;
  align-items: center;
  width: 100%;
}
.logoLink {
  color: $white;
  display: flex;
  align-items: center;
  margin-left: rem(-56);

  &:hover,
  &:visited,
  &:active,
  &:focus {
    color: $white;
    text-decoration: none;
  }

  svg {
    height: rem(32);
  }
}
.logo {
  margin-right: rem(8);
}
.logoText {
  color: inherit;
  font-size: rem(36);
  font-weight: $fontWeightBold;
  letter-spacing: rem(7);
  line-height: 1;
  text-transform: uppercase;
}
.navlink {    
  position: relative;
  line-height: $navbarHeight;
  font-size: $fontSizeMedium;
  font-weight: $fontWeightMedium;
  color: white;
  margin-left: rem(24);
  opacity: 0.7;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;

    &:after {
      border-bottom: 3px solid $brightBlue;
      content: '';
      display: block;
      position: absolute;
      bottom: rem(10);
      width: 100%;
    }
  }
}

.search {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}

.rightSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
}

.meatBallMenu {
  background: $blueGray;
  font-family: $fontFamilyHeading;
  padding: 0 0 rem(5);
  position: absolute;
  top: 100%;
  right: $contentSideMarginWidth;
  width: rem(165);
}

.menuItem {
  color: $white;
  display: block;
  font-size: rem(14);
  padding: rem(5) rem(10);
  
  &:hover,
  &:visited,
  &:active {
    color: $white;
    text-decoration: none;
  }
  
  &:hover {
    background: $brightBlue;    
  }
}

.menuItemActive {
  background-color: $mediumGray;
}

$meatballSpacing: rem(-11);

.meatBallButton {
  background: none;
  border: none;
  height: $navbarHeight;
  padding: 0 rem(6);
  margin-right: rem(-6); // minus padding to align with content edge
  margin-left: rem(16);
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}
.meatball {
  background: $white;
  border-radius: 50%;
  border: none;
  display: block;
  height: rem(6);
  width: rem(6);
}
.meatballs {
  display: block;
  font-size: 0;
  position: relative;
  @extend .meatball;
  
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    @extend .meatball;
  }
  &:before {
    top: $meatballSpacing;
  }
  &:after {
    bottom: $meatballSpacing;
  }
}