@import '../../../styles/globals.scss';


.addButton {
  background: transparent;
  border: none;
  color: $brightBlue;
  cursor: pointer;
  display: flex;
  align-items: center;
  // font-family: $fontFamily;
  font-size: $fontSizeMedium;
  font-weight: $fontWeightSemiBold;
  line-height: 2;
  padding: 0;
  
  &:hover,
  &:active {
    color: $darkestBlue;
  }

  &:disabled {
    color: $blueGrayLight;
    cursor: not-allowed;
  }

  svg {
    font-size: inherit;
  }
}