@import '../../styles/globals.scss';

.sideNav {
    background: transparent linear-gradient(#F8F8F8 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: rem(302);
    flex-basis: calc(100%/(1368/302));
}

.sideNavHeader{
    align-self: center;
    text-transform: uppercase;
    opacity: 0.4;
}
.secondHeader{
    margin-top: 65px;
}

.detailNavItem{
    margin-bottom: 12px;
}

.navListHeading {
    font-size: $fontSizeExtraSmall;
    font-weight: $fontWeightMedium;
    margin-bottom: rem(10);
    opacity: 0.4;
    padding-left: rem(12);
}

.navList {
    list-style: none;
    padding: 0;
}

.actionContents{
    display: flex;
}

.spinnerContent{
    text-align: center;
    padding: 0 60px;
}

.downloadButton {
    cursor: pointer;
    margin-left: 8px;
    color: $brightBlue;
    fill: $brightBlue;
}