@import '../../styles/globals.scss';

.overlay {
  background: $overlay;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: $loaderZ;
}
.logo {
  margin-bottom: rem(20);
}
.spinner {
  color: $white;
  cursor: default;
  pointer-events: inherit;
}