@import '../../../styles/globals.scss';

.pointsField {
  margin-left: auto;
  width: rem(80);
  text-align: right;

  input {
    font-weight: $fontWeightSemiBold;
  }
}
.pointsRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.requirement {
  margin: 0 0 rem(10);
  p {
    font-family: $fontFamilyBody;
    font-weight: $fontWeightMedium;
    font-size: rem(16);
    margin: 0;
  }
}
.subRequirement {
  margin-left: rem(30);
}

.groupHeading {
  margin: 0;
}

.grandTotal {
  font-family: $fontFamilyBody;
  font-weight: $fontWeightMedium;
  font-size: rem(20);
  text-align: right;
}

.grandTotalValue {
  font-family: $fontFamilyMonospace;
  font-weight: $fontWeightSemiBold;
  font-size: rem(18);
}

.table th:last-child,
.table td:last-child {
  text-align: right;
  width: 20%;
}

.parentRow {
  font-weight: $fontWeightSemiBold;
}