@import '../../../styles/globals.scss';

.uploadButton {
  background: transparent;
  border: none;
  color: $brightBlue;
  fill: $brightBlue;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: $fontFamilyBody;
  font-weight: $fontWeightSemiBold;
  outline: none;
  padding: rem(4);
  width: auto;
  margin-bottom: 20px;
  margin-top: 5px;

  &:focus {
    outline: 1px solid $brightBlue;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  svg {
    margin-right: rem(5);
  }
}