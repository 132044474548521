@import '../../../../styles/globals.scss';

.header {
  display: flex;
  justify-content: space-between;
  margin: 0 0 rem(20);
}
.manageContacts {
  display: flex;
}

.groupSelect {
  flex-grow: 1;
  margin-left: rem(20);
  width: rem(145);
}
