@import './colors.scss';
@import './functions.scss';
@import './mixins.scss';
@import './typography.scss';

// i think we should bundle these font files instead of relying on google
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// old variables
$text-x-small: 0.7rem;
$text-small: 0.86rem;
$text-medium: 1rem;
$text-large: 1.3rem;
$text-x-large: 2.1rem;
$headerHeight: 150px;
$sideContentWidth: 160px;


// general variables should go here.
// keep variable names $camelCase
$contentWidth: rem(1236);
$contentSideMarginWidth: rem(65);
$navbarHeight: rem(50);
$borderRadius: 4px;
$gridGap: rem(4);

// modals, popups, tooltips
$boxShadowStyle: 0 0 rem(10) $boxShadow;

// forms
$fieldHeight: rem(36);

// z-index
$headerZ: 10;
$navbarZ: 11;
$noNavBarZ: 12;
$modalZ: 13;
$searchZ: 14;
$loaderZ: 15;
