@import '../../../styles/globals.scss';

.requirementsTable {
  margin: 0 0 rem(60);
}
.row {
  display: flex;
  align-items: center;
}
.zebraRow {
  display: flex;
  align-items: center;
  font-family: $fontFamilyHeading;
  min-height: rem(40);
  padding-left: rem(20);
  position: relative;
  width: rem(500);
}

.row:first-of-type .zebraRow {
  border-top: 2px solid $darkestBlue;
}
.row:nth-child(odd) .zebraRow {
  background: $lightGray;
}

.requirementsLabel {
  font-weight: $fontWeightBold;
}
.requirementText {
  font-weight: $fontWeightSemiBold;
}

// riskFactor values
$low: #27BA1C;
$medium: #FFD802;
$high: #D00008;

.riskFactor {
  display: block;
  font-size: 0;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: rem(16);

  &.low {
    background-color: $low;
  }
  &.low-medium {
    background: linear-gradient($medium 20%, $low 80%);
  }
  &.medium {
    background-color: $medium;
  }
  &.medium-high {
    background: linear-gradient($high 20%, $medium 80%);
  }
  &.high {
    background-color: $high;
  }
}

.note {
  display: flex;
  align-items: center;
  margin: 0 0 0 rem(20);
  padding: 0;
}

.base {
  margin-left: rem(15);
}

.requirementLabel {
  margin: rem(10) 0 rem(4);
}

.noBasesMsg {
  padding-top: 20px;
  display: block;
}