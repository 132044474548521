// Color Definitions
$white: #FFFFFF;
$black: #000000;
$green: #0CB720;
$yellow: #FFD802;
$purple: #661FFF;
$red: #CC2727;
$pink: #FFC0CB;

// grays
$lightGray: #F1F2F7;
$lightestGray: #f8f8fb;
$darkestGray: #313131;
$mediumGray: #707070;
$borderGray: #E3E4EC;

// blues
$palestBlue: #e3e4ff;
$blueGrayLight: #9c9fbc;
$blueGray: #393B58;
$boldBlue: #222AA9;
$headerGradientStart: #121A95;
$headerGradientEnd: #090D4B;
$footerBlue: #363858;
$darkestBlue: #05082F;
$mediumBlue: #484EFC;
$brightBlue: #2773CC;
$primaryBlue: #3577F4;

// Global Color Applications
$textDark: $darkestBlue;

$boxShadow: rgba($black,0.5);
$overlay: rgba($black,0.7);

$danger: $red;
$dangerLight: $pink;
