@import '../../../styles/globals.scss';

.titleGrid {
  margin-left: rem(25);
}
.pointsLabel {
  display: block;
  text-align: right;
}
.groupHeading {
  margin: 0;
}

.table td:last-child {
  width: 10%;
}

.label{
  align-self: center;
}

.heading {
  font-size: rem(16);
  font-weight: $fontWeightMedium;
}
.subHeading{
  font-size: rem(13);
  font-weight: $fontWeightMedium;
  margin-bottom: 0;
}

.categoryRow {
  font-weight: $fontWeightBold;
}
.subRequirementRow {
  font-weight: $fontWeightSemiBold;
}
.subreq{
  font-weight: 600;
}
