@import '../../styles/globals.scss';

.modal {
  max-width: rem(800);
}

.questionsList {
  padding-left: rem(14);
}

.questionListItem {
  font-weight: initial;
  margin-bottom: rem(30);
}

.question {
  font-weight: $fontWeightSemiBold;
}

.expediteWarning {
  margin-top: rem(20);
}

.afiList li {
  margin-bottom: rem(10);
}