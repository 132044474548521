@import '../../styles/globals.scss';

.titleRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: rem(16);
}
.title {
  font-size: rem(28);
  line-height: 1;
  margin-bottom: 0;
}

.spinner {
  position: absolute;
  right: rem(-20);
}

.titleButtonContainer{
  display: flex;
  position: relative;
}

.titleButtons {
  display: flex;
  align-items: baseline;
}

.titleButtons * {
  display: inline-block;
  margin-left: rem(11);
  padding: 0 rem(4);
  position: relative;

  &:before {
    background-color: $brightBlue;
    content: '';
    display: block;
    width: 2px;
    height: 80%;
    position: absolute;
    left: rem(-6);
    top: rem(2)
  }

  &:first-child {
    margin-left: rem(10);

    &:before {
      content: initial;
    }
  }
}

.bars {
  list-style: none;
  padding: 0;
}

.bar {
  display: flex;
  justify-content: space-between;
}
.bar + .bar {
  margin-top: rem(12);
}
.barTitle {
  font-size: $fontSizeMedium;
  margin-right: rem(50);
}

.determinationLabel {
  font-size: $fontSizeMedium;
  font-weight: $fontWeightMedium;
}
.determinationBar {
  padding: 0 0 rem(8);
}
.determinationSelectFields {
  margin: 0;
}
.generateMaterials {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.readAhead {
  margin-top: rem(30);
}
.readAheadTitle {
  font-size: rem(20);
  font-weight: $fontWeightMedium;
  margin-bottom: rem(10);
}
.outOfDate {
  color: red;
}