@import '../../styles/globals.scss';

.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: rem(30);
}

.filter {
  margin-right: rem(10);
}