@import '../../styles/globals.scss';

.navListItem {
  align-items: center;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  font-family: $fontFamilyHeading;
  font-size: $fontSizeMedium;
  justify-content: space-between;
  margin: 0 0 16px;
  padding: rem(4) rem(24) rem(4) rem(12);
  position: relative;
  width: 100%;
  @include transition(color);

  &:focus,
  &:hover {
    color: $boldBlue;
    outline: none;
    text-decoration: underline;
  }
}

.active {
  position: absolute;
  left: 0;
  top: 5px;
  background: $darkestBlue;
  clip-path: polygon(0% 0%, 0% 100%, 100% 50%);
  margin-right: 8px;
  width: 5px;
  height: 12px;
}

.title {
  line-height: 1;
  text-align: left;
}
@mixin icon {
  background: transparent;
  border-radius: 50%;
  display: block;
  height: rem(16);
  width: rem(16);
  position: absolute;
  right: 0;
  top: rem(4);
}
.iconEmpty {
  @include icon;
  border: 2px solid $palestBlue;
}
.iconWarning {
  @include icon;
  background: $yellow;
}
.iconSuccess {
  @include icon;
  background: $green;
}