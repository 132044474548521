@import '../../styles/globals.scss';

.dropdown {
  :global .reactSelect__control {
    background: transparent;
    border: 2px solid rgba($mediumBlue, 0.15);
    border-radius: 4px;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    height: 36px;
    width: 100%;

    &:focus {
      border-color: rgba($mediumBlue, 0.75);
    }
  }
}