@import '../../../styles/globals.scss';

$borderRadius: rem(20);

.button {
  cursor: pointer;
  display: inline-block;
  font-family: $fontFamilyBody;
  font-size: $fontSizeExtraSmall;
  font-weight: $fontWeightSemiBold;
  @include transition(background-color);

  &:disabled {
    cursor: default;
  }

  & + .button {
    margin-left: rem(10);
  }
}
.pill {
  border-radius: $borderRadius;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  min-width: rem(124);
  height: rem(40);
  padding-left: rem(16);
  padding-right: rem(16);
  text-transform: uppercase;
  white-space: nowrap;
}

.primary {
  @extend .pill;
  background-color: $white;
  border: 1px solid $brightBlue;
  color: $brightBlue;

  &:hover,
  &:active {
    background-color: $brightBlue;
    color: $white;
  }

  &:disabled {
    background-color: $lightGray;
    border-color: $lightGray;
    color: $blueGrayLight;
    cursor: not-allowed;
  }
}

// no pill, just text
.secondary {
  background: transparent;
  border: none;
  color: $brightBlue;
  padding: 0;

  &:hover,
  &:active {
    color: $darkestBlue;
  }

  &:disabled {
    color: $blueGrayLight;
    cursor: not-allowed;
  }
}

// use these when a button appears on a dark background
.primaryAlt {
  @extend .pill;
  background-color: $primaryBlue;
  border: none;
  color: $white;

  &:hover,
  &:active {
    background-color: $brightBlue;
  }

  &:disabled {
    background-color: #172e95;
    color: #6f7dbf;
    cursor: not-allowed;
  }
}

.secondaryAlt {
  @extend .pill;
  background: transparent;
  border: 1px solid $white;
  color: $white;

  &:hover,
  &:active {
    background: $white;
    color: $darkestBlue;
  }

  &:disabled {
    color: $blueGrayLight;
    cursor: not-allowed;
  }
}

.danger {
  @extend .pill;
  background-color: $danger;
  border: 1px solid transparent;
  color: $white;

  &:hover,
  &:active {
    background: $white;
    border-color: $danger;
    color: $danger;
  }

  &:disabled {
    background: $dangerLight;
    color: $blueGrayLight;
    cursor: not-allowed;
  }
}