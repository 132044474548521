@import '../../../styles/globals.scss';

.comments {
  padding-top: rem(50);
}

.commentsHeader {
  font-family: $fontFamilyHeading;
  font-size: $fontSizeMedium;
  font-weight: $fontWeightSemiBold;
  margin-bottom: rem(14);
}

.comment {
  font-family: $fontFamilyHeading;
}
.comment + .comment {
  margin-top: rem(4);
}

.comment:last-of-type {
  margin-bottom: rem(16);
}
.commentBox {
  background: $lightestGray;
  font-size: $fontSizeMedium;
  width: 650px;
  padding: rem(20);
  position: relative;
}

.commentBody {
  display: inline;
  margin: 0 0 rem(20);
}
.commentBoxActions {
  color: $brightBlue;
  display: block;
  float: right;
  font-size: $fontSizeMedium;
  font-weight: $fontWeightMedium;
  padding: 0 0 rem(20) rem(20);

  button {
    font-size: $fontSizeMedium;
  }
}

.commentFooter {
  color: $blueGrayLight;
  font-size: $fontSizeSmall;
  padding-top: rem(20);
}

.buttonRow {
  padding: rem(50) 0;
}

// replace this with SVG when it's available
.infoIcon{
  width: 11px;
  height: 11px;
  line-height: 11px;
  border-radius: 50%;
  font-size: 9px;
  color: #fff;
  text-align: center;
  background: $brightBlue;
  display: inline-block;
  vertical-align: middle;
}