@import '../../styles/globals.scss';

.header {
  background-color: $darkestBlue;
  box-shadow: 0px 0px 10px $boxShadow;
  color: $white;
  font-family: $fontFamilyHeading;
  margin: $navbarHeight 0 0;
  min-height: $headerHeight;
  padding: rem(30) 0 rem(40);
  width: 100%;
  position: relative;
  z-index: $headerZ;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 rem(65);
  max-width: 100%;

  h1 {
    font-size: rem(32);
    font-weight: $fontWeightSemiBold;
    margin: 0;
  }
}

.leftContent {
  width: 80%;
}

.rightContent {
  margin-left: auto;
}

.subTitle {
  font-size: rem(32);
  font-weight: $fontWeightLight;
}