@import '../../../styles/globals.scss';

.stepNav {
  margin: 0 auto;
  max-width: $contentWidth;
}

.navigation {
  // margin: 0 auto;
  // max-width: $contentWidth;
  position: relative;
}
.steps {
  display: flex;
  align-items: center;

}

.step {
  cursor: default;
  height: rem(78);
  opacity: 0.4;
  outline: none;
  position: relative;
  text-align: center;
  @include transition(all);

  svg {
    fill: transparent;
    height: rem(78);
    overflow: visible;
    stroke: $white;
    stroke-width: rem(2);
  }
  &.active,
  &.enabled {
    opacity: 1;
    svg {
      stroke: none;
    }
  }

  &.enabled {
    cursor: pointer;

    svg {
      fill: $boldBlue;
      stroke: $boldBlue;
     }
  }

  &.active {
    svg {
      fill: $brightBlue;
      stroke: $brightBlue;
    }
  }
}

.stepContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: rem(20) rem(15);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

.step:first-child .stepContent {
  padding-left: rem(5);
}

.step:last-child .stepContent {
  padding-right: rem(5);
}

.stepTitle {
  font-size: rem(14);
  font-weight: $fontWeightSemiBold;
  margin: 0;
}

.stepSubTitle {
  font-size: rem(11);
  position: absolute;
  bottom: rem(4);
  left: 0;
  right: rem(15);
  width: 100%;
}

.prevPhaseButton,
.nextPhaseButton {
  background-color: transparent;
  border: none;
  color: $white;
  cursor: pointer;
  height: rem(40);
  opacity: 0.7;
  padding: rem(10);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  @include transition(opacity);

  &:hover {
    opacity: 1;
  }

  &:disabled {
    opacity: 0;
  }
  svg {
    height: 100%;
  }
}
.prevPhaseButton {
  left: rem(-60);
}

.nextPhaseButton {
  right: rem(-60);
}