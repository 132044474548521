@import '../../../styles/globals.scss';

$fieldHeight: 36px;

.SingleDatePickerInput {
  border: none;
}
.SingleDatePickerInput {
  padding-right: rem(27);
}
.SingleDatePickerInput_clearDate {
  margin: 0;
  padding: rem(6);
}
.SingleDatePickerInput_clearDate_svg {
  fill: $brightBlue;
  height: rem(14);
  width: rem(14);
}
.DateInput_input {
  border: 2px solid $palestBlue;
  border-radius: $borderRadius;
  font-size: $fontSizeMedium;
  height: $fieldHeight;
  outline: none;
  padding: 8px 12px;
  font-family: inherit;
}

.CalendarMonth_caption strong {
  color: $textDark;
  font-family: $fontFamilyHeading;
  font-weight: $fontWeightMedium;
}
.CalendarDay {
  font-family: $fontFamilyMonospace;
}

.CalendarDay__default:hover {
  background-color: $blueGrayLight;
  border-color: $blueGrayLight;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: $brightBlue;
  border-color: $brightBlue;
}