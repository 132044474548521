@import '../../styles/globals.scss';

.body {
  overflow: hidden;
  height: 100vh;
}

.search {
  background-color: $darkestBlue;
  overflow-y: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $searchZ;

  &.showingResults {
    background-color: $white;
  }
}

.searchTop,
.searchBottom {
  background-color: $darkestBlue;
  color: $white;
  padding: rem(20) rem(30);
}

.sectionlabel {
  display: inline-block;
  opacity: 0.62;
  text-transform: uppercase;
}

.closeButton {
  color: $white;
  position: absolute;
  right: rem(30);
  top: rem(20);

  &:hover {
    color: $white;
    opacity: 0.6;
  }
}

.form {
  display: flex;
}

.queryWrapper {
  background: $white;
  border-radius: $borderRadius;
  flex-grow: 1;
  height: rem(50);
  margin-left: rem(15);
  position: relative;
}

.input {
  background: transparent;
  border: none;
  display: block;
  font-size: rem(26);
  padding: 0 rem(13);
  width: 100%;
  height: 100%;
  outline: none;
}

.suggestions {
  background: $white;
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: rem(60);

  li {
    color: $textDark;
    cursor: pointer;

    button {
      background: transparent;
      border: none;
      cursor: pointer;
      display: block;
      padding: rem(10) rem(20);
      width: 100%;
      text-align: left;

      &:hover {
        background: gray;
      }
    }
  }
}
.clearInputButton {
  background: transparent;
  border: none;
  color: blue;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  text-transform: uppercase;
  padding: 0 rem(13);
  position: absolute;
  right: 0;
  top: 0;
}

.suggestionSelect,
.typeSelect {
  :global .reactSelect__control {
    background: transparent;
    font-family: inherit;
    font-size: inherit;
    height: rem(50);
  }
  :global .reactSelect__dropdown-indicator {
    svg {
      display: none;
    }
  }
  :global .reactSelect__indicator-separator {
    display: none;
  }
  :global .reactSelect__menu {
    z-index: 1;
  }
  :global .reactSelect__option {
    cursor: pointer;
  }
}

.typeSelect {
  border: 2px solid $brightBlue;
  border-radius: $borderRadius;
  color: $white;
  font-family: $fontFamilyHeading;
  font-size: rem(16);
  width: rem(330);

  :global .reactSelect__control {
    border: 2px solid rgba($mediumBlue, 0.15);
    border-radius: 4px;
    cursor: pointer;
    font-weight: $fontWeightMedium;

    &:focus {
      border-color: rgba($mediumBlue, 0.75);
    }
  }

  :global .reactSelect__placeholder,
  :global .reactSelect__single-value {
    color: $white;
  }

  :global .reactSelect__menu {
    background: $brightBlue;
  }

  :global .reactSelect__option--is-focused {
    background-color: $white;
    color: $darkestBlue;
  }
  // create the arrow
  &:after {
    background: $white;
    content: '';
    display: block;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    width: 9px;
    height: 4px;
    position: absolute;
    right: 16px;
    top: 23px;
  }
}
.searchInUse {
  background: $brightBlue;
}

.suggestionSelect {
  :global .reactSelect__control {
    border: none;
    cursor: text;
    font-size: rem(26);
    padding: 0 rem(3);
  }
  :global .reactSelect__menu {
    border-radius: 0;
    left: rem(10);
    width: auto;
  }
  :global .reactSelect__option {
    color: $darkestBlue;

    font-size: rem(16);
    font-weight: $fontWeightMedium;
  }
}

.filterButtons {
  display: flex;
  flex-wrap: wrap;
}

.filterButton {
  background-color: transparent;
  border: 2px solid $brightBlue;
  border-radius: $borderRadius;
  color: $white;
  cursor: pointer;
  font-size: rem(16);
  font-weight: $fontWeightMedium;
  height: rem(50);
  line-height: 1;
  margin-bottom: rem(20);
  margin-right: rem(30);
  padding: rem(15);

  &:hover,
  &.selectedFilter {
    background-color: $brightBlue;
  }

  & + .filterButton {
    margin-left: 0;
  }
}

.searchResults {
  background-color: $white;
  color: $darkestBlue;
  margin: 0 auto;
  padding: rem(30) rem(20) rem(100);
  position: relative;
  width: rem(490);
}

.resultsHeader {
  display: flex;
  justify-content: space-between;
  margin: 0 0 rem(30);
}

.resultsCount {
  font-size: rem(16);
  font-weight: $fontWeightSemiBold;
  margin: 0;
}
.sortButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.sortButton {
  background-color: transparent;
  border: none;
  color: $brightBlue;
  font-family: $fontFamilyHeading;
  font-size: rem(12);
  font-weight: $fontWeightBold;
  height: rem(23);
  padding: 0;
  text-transform: uppercase;
  opacity: 0.7;
}

.sortButton + .sortButton {
  margin-left: rem(20);
}
.sortButtonDate {
  display: flex;
  align-items: center;
  svg {
    height: rem(18);
    margin-right: rem(5);
  }
}
.sortButtonAlpha {
  width: rem(23);

  svg {
    height: rem(23);
  }
}
.sortSelected {
  opacity: 1;
}

.noResultsFound {
  margin: rem(20) 0;
  text-align: center;
}

.result {
  font-family: $fontFamilyHeading;
  font-size: rem(16);
  font-weight: $fontWeightMedium;
}
.result + .result {
  margin-top: rem(40);
}

.resultHeading {
  margin: 0;
}
.resultTitle {
  color: $brightBlue;
  font-size: rem(20);
  font-weight: $fontWeightMedium;
  margin: 0;
}
.resultContactInfo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 1.5;
  margin: rem(5) 0;

  span {
    position: relative;
    margin-right: rem(15);

    &:after {
      content: '|';
      display: block;
      position: absolute;
      top: 0;
      right: rem(-10);
    }

    &:last-child:after {
      content: none;
    }
  }
}
.resultDetails {
  font-size: rem(13);
  font-weight: $fontWeightNormal;
}
.resultSource {
  color: $blueGrayLight;
  font-size: rem(12);
  font-style: italic;
  font-weight: $fontWeightNormal;
  text-align: right;
}

.resultPhone {
  margin: 0;
}
.delimited + .delimited {
  &:before {
    content: '|';
    margin: 0 rem(5);
  }
}
.daysToShow {
  position: absolute;
  top: rem(30);
  right: 100%;
  margin-right: rem(30);
  width: rem(160);
}

.daysToShowHeading {
  font-size: $fontSizeExtraSmall;
  font-weight: $fontWeightMedium;
  margin-bottom: rem(10);
  opacity: 0.4;
  text-transform: uppercase;
}

$fieldHeight: 36px;
.daysToShowSelect {
  font-family: $fontFamilyMonospace;
  font-weight: $fontWeightMedium;
  :global .reactSelect__control {
    background: #F1F2F7;
    border: none;
    border-radius: 0;
    outline: none;
  }
  :global .reactSelect__indicator-separator {
    display: none;
  }
  :global .reactSelect__control {
    cursor: pointer;
    min-height: $fieldHeight;
    width: 100%;

    &:focus {
      border-color: rgba($mediumBlue, 0.75);
    }
  }
  :global .reactSelect__value-container {
    padding: 0 rem(12);
  }
  :global .reactSelect__dropdown-indicator {
    svg {
      display: none;
    }
  }
  :global .reactSelect__indicator-separator {
    display: none;
  }
  :global .reactSelect--is-disabled,
  :global .reactSelect__control--is-disabled {
    pointer-events: auto;
    cursor: not-allowed;
    opacity: 0.7;
  }

  :global .reactSelect__menu {
    z-index: 1;
  }
  // create the arrow
  &:after {
    background: #ccc;
    content: '';
    display: block;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    width: 9px;
    height: 4px;
    position: absolute;
    right: 10px;
    top: 16px;
  }
}