$fontFamilyHeading: 'Poppins', sans-serif;
$fontFamilyBody: 'IBM Plex Sans', sans-serif;
$fontFamilyMonospace: 'IBM Plex Mono', monospace;

$htmlFontSize: 16px; // please don't change this. html font-size is the "root" of our "root em"
$fontSizeExtraSmall: rem(12);
$fontSizeSmall: rem(13);
$fontSizeMedium: rem(16);


$fontWeightThin: 100;
$fontWeightExtraLight: 200;
$fontWeightLight: 300;
$fontWeightNormal: 400;
$fontWeightMedium: 500;
$fontWeightSemiBold: 600;
$fontWeightBold: 700;
$fontWeightExtraBold: 800;
$fontWeightBlack: 900;

