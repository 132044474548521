@import '../../../styles/globals.scss';

.dateGroup .dateGroupToggle {
  margin-bottom: 0;
}
.dateGroupFields {
  display: flex;
  justify-content: space-between;
}
.dateGroupNotes {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: rem(30);

  textarea {
    flex-grow: 1;
    margin-bottom: rem(24);
  }
}
.dateGroupDisplay {
  display: flex;

}
.datesTable {
  flex-basis: 75%;
  max-width: rem(500);

  th:not(:last-child),
  td:not(:last-child) {
    width: 25%;
  }
}
.notesGroup {
  margin-left: rem(30);
  flex-basis: 25%;
}
.notesLabel {
  font-family: $fontFamilyHeading;
  font-weight: $fontWeightSemiBold;
  margin: 0;
  padding: rem(10) 0;
}
.notesDisplay {
  padding: rem(10) 0;
}
.engagementTable {
  margin-bottom: rem(50);
}

.dateCell {
  max-width: rem(60);
}