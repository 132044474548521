@import '../../../styles/globals.scss';

.title {
  margin: 0;
}

.titleWrapper {
  margin-bottom: rem(24);
}

.requestorHeading {
  p {
    margin: 0;
  }
}