@import '../../styles/globals.scss';

.documentList {
  margin-bottom: 0;

  td,
  th {
    width: rem(120);
  }

  td:first-child,
  th:first-child {
    width: rem(300);
  }

  tfoot {
    td:first-child {
      padding-top: 0;
    }

    p {
      margin: 0;
    }
  }
}

.documentList + .documentList {
  margin-top: rem(20);
}

.docTitle{
  text-overflow: ellipsis;
  overflow: hidden;
}

.closeButton {
  position: absolute;
  right: rem(-24);
  top: rem(8);
}

.noAttachments {
  padding-top: 0;
}