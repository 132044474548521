@import '../../styles/globals.scss';

.footer {
  background-color: $footerBlue;
  color: $white;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 100vh;
  font-family: $fontFamilyHeading;
  font-size: rem(11);
  font-weight: $fontWeightMedium;

  a,
  a:active,
  a:focus,
  a:link,
  a:visited {
    color: $white;
    fill: $white;
    text-decoration: none;
  }

  p {
    margin: 0;
  }
}
.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: rem(5) $contentSideMarginWidth;
  width: 100%;
}
.saberText {
  text-transform: uppercase;
}

.mailto {
  display: flex;
  align-items: center;
  svg {
    height: rem(11);
    margin-right: rem(5);
  }
}