@import '../../styles/globals.scss';

$borderWidth: rem(16);
$borderThickness: rem(12);

.tooltip {
  :global(.tippy-content) {
    background-color: $white;
    border-radius: 0;
    box-shadow: $boxShadowStyle;
    color: $textDark;
    font-family: $fontFamilyHeading;
    padding: rem(10);
  }
  :global(.tippy-arrow) {
    border-style: solid;
    border-color: transparent;
  }
  &[data-placement^=top] {
    :global(.tippy-arrow) {
      border-bottom-width: 0;
      border-top-color: $white;
      border-top-width: $borderThickness;
      border-left-width: $borderWidth;
      border-right-width: $borderWidth;
    }
  }
  &[data-placement^=bottom] {
    :global(.tippy-arrow) {
      border-top-width: 0;
      border-bottom-color: $white;
      border-bottom-width: $borderThickness;
      border-left-width: $borderWidth;
      border-right-width: $borderWidth;
      top: -$borderThickness;
    }
  }
}