@import '../../styles/globals.scss';

.title {
  font-size: rem(28);
  font-weight: $fontWeightSemiBold;
  margin: 0;
}

.selectedGroup {
  font-size: rem(20);
  font-weight: $fontWeightMedium;
  min-height: rem(27);
}