@import '../../../styles/globals.scss';

.table {
  margin: 0 0 rem(24);
  
  td {
    width: rem(92);

    &:first-child {
      width: auto;
    }
  }
}