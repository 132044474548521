.flexTable{
    display: flex;
}

.barTitleFlex{
    flex-basis: 80%;
}

.statusFlex{
    flex-basis: 20%;
}