@import '../../styles/globals.scss';

.list {
  height: fit-content;
}

.rowEntry {
  display: flex;
  justify-content: space-between;
  padding-bottom: rem(9);
}

.rowEntry + .rowEntry {
  border-top: 1px solid $borderGray;
  padding-top: rem(9);
}

.linkContainer{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50%;
}

.rowEntry:hover .link {
  white-space: normal;
}

.rightText{
  color:#979797;
}