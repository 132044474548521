.modal{
    width: 500px;
    min-height: 300px;
}

.closeBtn{
    vertical-align: middle;
    margin-right: 10px;
}

.row{
    margin-bottom: 5px;
}

.explanation{
    margin-bottom: 20px;
}

.barTitle{
    color: #070F58;
}