@import '../../styles/globals.scss';

.steps {
  display: flex;
  justify-content: space-between;
  padding: 0 rem(62);
}

.step {
  background-color: transparent;
  border: 2px solid $white;
  border-radius: $borderRadius;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: rem(168);
  flex-grow: 1;
  min-height: rem(85);
  padding: rem(20) rem(16);
  position: relative;
  text-align: center;
  opacity: 0.4;
  @include transition(all);

  &:hover {
    opacity: 1;
  }
}

.step + .step {
  margin-left: rem(9);
}

.step.enabled,
.step.active {
  opacity: 1;
}

.step.enabled {
  border-color: $primaryBlue;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: $brightBlue;
    border-color: $brightBlue;
  }
}

.step.active {
  background-color: $primaryBlue;
  border-color: $primaryBlue;
}

// for extending only
.determinationDot {
  background-color: $yellow;
  border-radius: 50%;
  content: '';
  display: block;
  height: rem(14);
  width: rem(14);
}

.hasDeterminations {
  @extend .determinationDot;
  position: absolute;
  right: rem(-7);
  top: rem(-7);
}

.step p:last-of-type {
  margin-bottom: 0;
}
.stepTitle,
.stepSubTitle {
  width: 100%;
}

.stepTitle {
  font-size: rem(14);
  font-weight: $fontWeightSemiBold;
  margin: 0;
}

.noWrap {
  white-space: nowrap;
}
.stepSubTitle {
  font-size: rem(11);
}

.reviewModal {
  width: rem(500);
}

// sidebar
.sideNavList {
  list-style: none;
}
.sideNavListItemSelected {
  display: flex;

  &:before {
    content: '>';
  }
}

.sideNavButton {
  background: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
  display: flex;
  position: relative;
}
.determinationButton:after {
  @extend .determinationDot;
  position: absolute;
  right: 0;
}

.pdfModalSpacer {
  margin-bottom: 25px;
}

.modalDownload {
  vertical-align: text-top;
}