@import '../../styles/globals.scss';

.modal{
    width: 470px;
}

.registerPage{
    z-index: $noNavBarZ;
    background-color: #05082F;
    color:white;
    width: 100%;
    position: fixed;
    height: 100%;
    padding-top: 80px;
    padding-left: 120px;
    padding-right: 100px;
}

.welcome{
    font-size: 95px;
    font-weight: 500;
}

.saber{
    letter-spacing: 20px;
    font-weight: 650;
}

.saberMeaning{
    font-size: 18px;
}

.registerToStart{
    font-size: 50px;
    margin: 50px 0;
}

.thankYou{
    font-size: 50px;
    margin-bottom: 25px;
}

.notified{
    font-size: 28px;
    line-height: 1;
}

.logo{
    opacity: 0.15;
    height: rem(450);
    width: rem(450);
    bottom: rem(-15);
    right: rem(185);
    position: absolute;
}