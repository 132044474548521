@import '../../styles/globals.scss';

.roundSelect {
  width: 100%;
  :global .reactSelect__control {
    background: transparent;
    border: 2px solid rgba($mediumBlue, 0.15);
    border-radius: $borderRadius;
    cursor: pointer;
    font-family: $fontFamilyHeading;
    font-size: inherit;
    min-height: $fieldHeight;
    width: 100%;

    &:focus {
      border-color: rgba($mediumBlue, 0.75);
    }
  }

  &.error {
    :global .reactSelect__control {
      border-color: $danger;
    }
  }
  :global .reactSelect__value-container {
    padding: 0 rem(12);
  }
  :global .reactSelect__placeholder,
  :global .reactSelect__single-value {
    height: rem(20);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  :global .reactSelect__indicator {
    max-height: rem(32);
  }
  :global .reactSelect__dropdown-indicator {
    svg {
      display: none;
    }
  }
  :global .reactSelect__indicator-separator {
    display: none;
  }
  :global .reactSelect--is-disabled,
  :global .reactSelect__control--is-disabled {
    pointer-events: auto;
    cursor: not-allowed;
    opacity: 0.7;
  }

  :global .reactSelect__menu {
    z-index: 1;
  }
  // create the arrow
  &:after {
    background: #ccc;
    content: '';
    display: block;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    width: 9px;
    height: 4px;
    position: absolute;
    right: 10px;
    top: 16px;
  }
}