@import '../../../styles/globals.scss';

.weaponSystem {
  margin: 0 0 rem(24);
}

.weaponRow {
  margin: 0 0 rem(5);
  transform: scale(1); // forces tr to honor position relative
  position: relative;

  td:first-child {
    width: 21.8%;
  }
  td:last-child {
    width: rem(92);
  }
  button {
    position: absolute;
    right: rem(-25);
    top: rem(10);
  }
}