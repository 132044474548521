@import '../../../../styles/globals.scss';

.cardEditButton {
  position: absolute;
  right: rem(5);
  top: rem(5);
}

.buttonRow {
  margin-top: rem(24);
}