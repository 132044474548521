@import '../../styles/globals.scss';

.subTitle {
  font-weight: $fontWeightMedium;
  font-size: rem(20);
}

.bodyText {
  font-size: rem(13);
}

.radio {
  margin-bottom: rem(10);
  label:empty {
    display: none;
  }
  label + label{
    margin-top: rem(10);
  }
}

.fieldArray {
  margin-bottom: 0;
}
.submit {
  margin-bottom: rem(50);
}