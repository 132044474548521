@import '../../styles/globals.scss';

.table {
  border-collapse: collapse;
  margin: 0 0 rem(60);
  width: 100%;

  // all table cells
  th,
  td {
    padding: rem(10) rem(6);

    &:empty {
      padding: 0;
    }
  }

  // table head cells
  th {
    border-bottom: 2px solid $darkestBlue;
    font-family: $fontFamilyBody;
    font-weight: $fontWeightSemiBold;
    vertical-align: bottom;
  }

  &.noHeadBorder th {
    border-bottom: none;
  }
  tbody tr {
    position: relative;
    transform: scale(1); // hack for table rows ignoring position: relative;

    &:nth-child(odd) {
      background: $lightGray;
    }

    &:hover {
      outline: 1px solid $blueGrayLight;
      z-index: 1;
    }
  }

  // table body cells
  tbody td {
    vertical-align: top;

    &:first-child {
      padding-left: rem(20);
    }

    &:last-child {
      padding-right: rem(20);
    }
  }
}

button {
  &.sortHeaderButton {
    border: none;
    background: none;
    display: flex;
    font-family: inherit;
    justify-content: space-between;
    align-items: center;
    font-size: inherit;
    padding: 0;
    position: relative;

    &:after {
      background: #ccc;
      content: '';
      display: block;
      clip-path: polygon(100% 0%, 0 0%, 50% 100%);
      width: rem(9);
      height: rem(4);
      margin-left: rem(10);
    }
  }
  &.sortActive:after {
    background: $darkestBlue;
  }

  &.sortAscending:after {
    transform: rotate(180deg);
  }
}