@import '../../styles/globals.scss';

.sectionHeading {
  font-size: rem(20);
  font-weight: $fontWeightMedium;
  margin: 0;
}

.label {
  display: flex;
  font-family: $fontFamilyHeading;
  font-size: $fontSizeSmall;
  font-weight: $fontWeightMedium;
  line-height: 1.5;
  margin: 0 0 rem(3);
  min-height: rem(19);

  // &:not(:last-child),
  &:first-child {
    margin-top: rem(24);
  }
}

.requiredIndicator {
  font-size: $fontSizeSmall;
  margin-left: 5px;
  vertical-align: top;
}

.field {
  background: transparent;
  border: 2px solid $palestBlue;
  border-radius: $borderRadius;
  font-family: inherit;
}
.input {
  @extend .field;
  color: $textDark;
  display: block;
  height: $fieldHeight;
  outline: none;
  padding: rem(8) rem(12);
  width: 100%;

  &:focus {
    border-color: rgba($mediumBlue, 0.75);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type=number] {
    -moz-appearance: textfield;
    font-family: $fontFamilyMonospace;
    text-align: right;
  }
  &:read-only {
    border-color: transparent;
  }
}

.inputError {
  border-color: $danger;
}

.inputContainer {
  position: relative;
}
.numberRangeField input {
  font-family: $fontFamilyMonospace;
  text-align: right;
}
.radioField {
  margin-bottom: rem(20);
}

.radioGroupHorizontal {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: rem(10);

  .radioLabel ~ .radioLabel {
    margin-left: 40px;
  }
}
.radioGroupVertical {
  .radioLabel {
    margin-bottom: 20px;
  }
}
.radioLabel {
  cursor: pointer;
  display: flex;
  padding: rem(2);
  position: relative;

  &:before {
    border: 2px solid #222AA9;
    border-radius: 50%;
    content: '';
    display: block;
    height: rem(20);
    margin-right: rem(8);
    width: rem(20);
    min-width: rem(20);
  }

  &:hover,
  &:focus-within {
    background: $palestBlue;
  }
}

.radioLabelChecked {
  &:after {
    background: #222AA9;
    border-radius: 50%;
    content: '';
    display: block;
    height: 10px;
    position: absolute;
    width: 10px;
    left: 7px;
    top: 7px;
  }
}
.radioInput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkboxArrayField {
  margin: 0 0 rem(50);
}

.checkboxLabel {
  cursor: pointer;
  display: flex;
  margin-top: rem(10);
  position: relative;

  &:hover,
  &:focus-within {
    background: $palestBlue;
  }
}

.checkboxIcon {
  color: #222AA9;
  margin-right: 8px;
}

.checkboxLabel ~ .checkboxLabel {
  margin-top: rem(22);
}

.checkboxInput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.textarea {
  @extend .field;
  min-height: rem(80);
  resize: vertical;
  width: 100%;
}

.fieldArray {
  margin-bottom: rem(24);

  &:empty {
    margin-bottom: 0;
  }

  &.nestedFieldArray {
    margin-bottom: rem(24);

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

// delete .select after removing the DropDown component
.select {
  :global .reactSelect__control {
    background: transparent;
    border: 2px solid rgba($mediumBlue, 0.15);
    border-radius: 4px;
    font-family: inherit;
    font-size: inherit;
    height: $fieldHeight;

    &:focus {
      border-color: rgba($mediumBlue, 0.75);
    }
  }
}

.select {
  :global .reactSelect__control {
    cursor: pointer;
    min-height: $fieldHeight;
    width: 100%;

    &:focus {
      border-color: rgba($mediumBlue, 0.75);
    }
  }
  :global .reactSelect__value-container {
    padding: 0 rem(12);
  }
  :global .reactSelect__dropdown-indicator {
    svg {
      display: none;
    }
  }
  :global .reactSelect__indicator-separator {
    display: none;
  }
  :global .reactSelect--is-disabled,
  :global .reactSelect__control--is-disabled {
    pointer-events: auto;
    cursor: not-allowed;
    opacity: 0.7;
  }

  :global .reactSelect__menu {
    z-index: 1;
  }
  // create the arrow
  &:after {
    background: #ccc;
    content: '';
    display: block;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    width: 9px;
    height: 4px;
    position: absolute;
    right: 10px;
    top: 16px;
  }
}

.uploadDocsField {
  margin: 0 0 rem(30);
}

.mapImage {
  display: block;
  margin: 0 0 rem(40);
  width: 100%;
}

.uploadFieldActions {
  display: flex;
}

.uploadFieldAction {
  font-weight: $fontWeightSemiBold;
  padding: rem(4) rem(10) rem(4) 0;
  width: auto;
  margin-bottom: 20px;
  margin-top: 5px;
}

.uploadFieldAction + .uploadFieldAction {
  margin-left: rem(5);
  padding-left: rem(10);
  position: relative;

  &:before {
    content: '|';
    display: inline;
    position: absolute;
    left: rem(-4);
  }
}

.documentListEntry{
  padding: rem(10) rem(6) rem(10) rem(20);
}

.closeButton{
  vertical-align: middle;
  float: right;
}

.docListGrid{
  display: grid;
}

.uploadsDocsFieldButton {
  margin-bottom: rem(50);
}

.richTextDisplay {
  margin-bottom: rem(20);
}

.readOnlyValue {
  margin: 0;
  min-height: $fieldHeight;
  padding: rem(8) 0;
}

.readOnlyFieldValue {
  @extend .readOnlyValue;
}

.errorIndicator {
  background-color: $danger;
  border-radius: 50%;
  color: $white;
  display: block;
  font-family: $fontFamilyHeading;
  font-size: rem(12);
  font-weight: $fontWeightExtraBold;
  height: rem(16);
  line-height: 1.4;
  width: rem(16);
  position: absolute;
  left: rem(-24);
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.nestedRequirement {
  padding-left: rem(15);
}

.purposeStatement li:not(:last-child) {
  margin-bottom: 15px;
}