@import '../../styles/globals.scss';

.tableHead{
  font-size: 13px;
  font-weight: 600;
  color: #000000;
  cursor: pointer;
}

.grid{
  grid-gap: 0px !important;
  padding-left: 58px;
  padding-right: 58px;
}

.caretUp{
  width: 0;
  height: 0;
  display: inline-block;
  margin-left: 10px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  opacity: 0.2;
  border-bottom: 6px solid #070F58;
}
.caretDown{
  width: 0;
  height: 0;
  display: inline-block;
  margin-left: 10px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  opacity: 0.2;
  border-top: 6px solid #070F58;
}

.watchColumn{
  width: 6%;
}

.titleColumn{
  width: 28%;
}

.safColumn{
  width: 15%
}

.phaseStepColumn{
  width: 15%;
}

.statusColumn{
  text-transform: capitalize;
}

.dateColumn{
  width: 16%;
}

.flagIcon{
  width: 16px;
  height: 20px;
  fill: $mediumBlue;
  fill-opacity: 0.15;
  cursor: pointer;
}

.selectedFlagIcon{
  fill-opacity: 1;
}

.resetFilters{
  cursor: pointer;
  color: $mediumBlue;
  margin-left: 5px;
  font-size: 12px;
}

.filterDropdown{
  width: 12%;
  display: inline-block;
  margin-right: 9px;
  font-family: "Poppins", sans-serif !important;
  color: #151A80;
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 11px;
}

.tableHeaderText{
  width: 17%;
  font-size: 30px;
  display: inline-block;
}

.contentPadding{
  padding: 55px 40px 0 40px;
}

.topMatrix {
  margin-top: rem(20);
  min-width: rem(1280);
  width: calc(100% - 65px);
}

.row {
  display: flex;
  justify-content: flex-end;
  margin-bottom: rem(4);
}

// for extending only
.cell {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  font-weight: $fontWeightMedium;
  min-width: rem(160);
  width: 12%;
  height: rem(48);
  border: none;
  margin-left: rem(4);

  &:first-child {
    flex-grow: 0;
    justify-content: flex-end;
    margin-left: 0;
    width: rem(200);
  }
}
.phaseCell {
  @extend .cell;
  background-color: rgba($white, 0.1);
  @include transition(background-color);

  &:hover {
    background-color: rgba($white, 0.4);
  }
}

.topStepCell {
  @extend .cell;
  justify-content: flex-start;
  position: relative;

  .phaseCellTitle {
    background-color: rgba($white, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 rem(30) 0 rem(20);
    position: absolute;
    left: rem(10);
    right: 0;
    top: 0;
    height: rem(48);
    @include transition(background-color);
  }
  .topChevronStart,
  .topChevronEnd {
    fill: rgba($white, 0.2);
    height: rem(48);
    @include transition(fill);
  }

  .topChevronEnd {
    position: absolute;
    left: 100%;
    top: 0;
  }

  &:first-child {
    background-color: rgba($white, 0.2);
    pointer-events: none;
  }

  &:hover {
    .phaseCellTitle {
      background-color: rgba($white, 0.4);
    }
    .topChevronStart,
    .topChevronEnd {
      fill: rgba($white, 0.4);
    }
  }
}

.phaseStepCell {
  @extend .cell;
  background-color: rgba($white, 0.1);
  color: $white;
  @include transition(background-color);

  &:hover {
    background-color: $purple;
  }
}

.percentileOne {
  background: #1D2E77;
}
.percentileTwo {
  background: #1D348E;
}
.percentileThree {
  background: #243EA4;
}
.percentileFour {
  background: #284BA9;
}
.percentileFive {
  background: #2B56B0;
}
.percentileSix {
  background: #306CBA;
}
.percentileSeven {
  background: #306EBB;
}
.percentileEight {
  background: #337AC0;
}
.percentileNine {
  background: #3787C7;
}
.percentileTen {
  background: #3A92CD;
}

.phaseStepCellEmpty {
  color: rgba($white, 0.2);
}
.phaseStepCellSelected {
  background-color: $purple;
}
.phaseStepCellNotSelected {
  background-color: rgba($white, 0.1);
}
.phaseCellText {
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-right: rem(12);
  width: rem(90);

}

.phaseCellCount {
  display: flex;
  justify-content: center;
  align-items: center;
}
.phaseCellTotal {
  font-size: rem(32);
  font-weight: $fontWeightLight;
}

.phaseCellUnit {
  font-size: rem(11);
  font-weight: $fontWeightMedium;
  line-height: 1;
  margin-left: rem(5);
  text-transform: uppercase;
}


.title {
  font-weight: $fontWeightSemiBold;
  font-size: 32px;
}
.subTitle {
  font-size: 24px;
}
