@import '../../styles/globals.scss';

.anchor {
  background-color: transparent;
  border: none;
  color: $brightBlue;
  fill: $brightBlue; // using fill so that SVGs inherit color
  cursor: pointer;
  display: inline;
  font-weight: $fontWeightSemiBold;
  line-height: 1.2;
  padding: 0;
  text-decoration: none;
  @include transition(color);
  
  &:visited {
    color: $brightBlue;
    fill: $brightBlue;
  }
  
  &:hover,
  &:active,
  &:focus {
    color: $boldBlue;
    fill: $boldBlue;
    text-decoration: underline;
  }

  &.light {
    font-weight: $fontWeightNormal;
  }

  &.disabled {
    color: $blueGrayLight;
    fill: $blueGrayLight;
    cursor: not-allowed;
    text-decoration: none;
  }
}
