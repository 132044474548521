@import '../../styles/globals.scss';

.modalOverlay {
  background: $overlay;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding: rem(80) 0 rem(40);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $modalZ;
}

.modal {
  background: $white;
  box-shadow: 0 0 rem(10) $boxShadow;
  display: flex;
  flex-direction: column;
  padding: rem(40);
  position: relative;

  &.modalCentered {
    align-items: center;
    text-align: center;
  }
}

.modalCloseIcon {
  cursor: pointer;
  position: absolute;
  right: rem(15);
  top: rem(15);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: rem(20);

}
.modalHeaderTitle {
  font-size: rem(24);
  font-weight: $fontWeightSemiBold;
  margin: 0;
}

.modalContent {
  padding: rem(10) 0;
}

.modalFooter {
  margin-top: rem(20);
}