@import '../../../styles/globals.scss';

.phaseStep {
  font-size: rem(24);
  font-weight: $fontWeightLight;
  margin: 0;
}

h1.title {
  font-size: rem(32);
  font-weight: $fontWeightSemiBold;
  // margin-right: rem(50);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.viewedPhase {
  font-size: $fontSizeExtraSmall;
}

.leftContent {
  margin-right: rem(65);
}

.rightContent {
  display: flex;
  justify-content: flex-end;
}

.editModal {
  width: rem(400);
}

.expediteConfirmModal {
  width: rem(400);
}
.flagIcon{
  width: 12px;
  height: 18px;
  fill: #FFFFFF;
  fill-opacity: 0.2;
  vertical-align: middle;
  margin-right: 7px;
}

.selectedFlag{
  fill-opacity: 1;
}

.watchButtonPadding{
  padding-top: 5px;
}

.editButton {
  color: $white;
  opacity: 0.6;
  margin-left: rem(10);

  &:hover,
  &:active {
    color: $white;
    opacity: 1;
  }
}