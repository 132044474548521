@import '../../../styles/globals.scss';

.closeButton {
  background: transparent;
  border: none;
  color: $brightBlue;
  cursor: pointer;
  padding: 0;

  &:hover,
  &:active {
    color: $darkestBlue;
  }
}

.hidden {
  font-size: 0;
  text-indent: rem(-9999);
}