@import '../../styles/globals.scss';

.grid {
  position: relative;
  align-items: center;

  // any non-grid following a grid will get top margin
  & + * {
    margin-top: rem(24);
  }

  & + .grid {
    margin-top: rem(4);
    outline: none;
  }

  .grid {
    margin-bottom: 0;
  }
}

.absoluteChild {
  position: absolute;
  left: 100%;
}