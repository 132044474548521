@import '../../../styles/globals.scss';

.subRequirementRow {
  padding-left: rem(15);
}
.timingField {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.fiscalQuarter {
  width: rem(142);
}
.timingDefinition {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: $gridGap;
  max-width: 100%;

  textarea {
    flex-grow: 1;
  }
}