@import '../../../styles/globals.scss';

.topGrid{
  margin-bottom: 25px;
}

.docTitle{
  text-overflow: ellipsis;
  overflow: hidden;
}

.datepicker{
  margin-bottom: 0;
}

.bigSpacer{
  display: inline-block;
  margin-top: 25px;
  margin-bottom: 25px;
}

.smallSpacer{
  margin-bottom: 20px;
}

.signedDecisionPackageHeading {
  font-size: rem(13);
  font-weight: $fontWeightSemiBold;
  padding: rem(6);
}
.signedDecisionPackage {
  display: flex;
  flex-direction: column;
}

.label {
  margin: 0 rem(15) 0 0;
}
.fileRow {
  display: flex;
  align-items: center;
  min-height: rem(28);
  position: relative;
}

.closeButton {
  position: absolute;
  right: 0;
  top: rem(5);
}
.dateRow {
  display: flex;
  align-items: center;
}